import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { BodySmall, Button, Checkbox, Div, Flex, H2 } from '@beauty/beauty-market-ui';
import { FormikInput, FormikPasswordInput, FormikPhone } from '../../../components';
import { AccType, invalidHints } from '../../../constants';
import { getInvalidType, getShortCountryByCode, registration, signupPhoneVerification } from '../../../helpers';
import { useFocus, useToggle } from '../../../hooks';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch } from '../../../store/hooks';
import { updateSmsCodeTime, updateUser } from '../../../store/redux-slices/userSlice';
import {
  DecodedToken,
  initialvalues,
  RegistrationForm,
  RegistrationFormFields,
  registrationFormValidationSchema,
} from './Registration.definitions';

const Registration = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useFocus();

  const [isNotifications, setNotifications] = useToggle(true);

  const { Number, Name, Email, Region, Surname, IdNumber, Password } = RegistrationFormFields;

  const onFormSubmit = useCallback(
    async (data: RegistrationForm, { setFieldError }) => {
      const decodedToken: DecodedToken = jwtDecode(state?.token);
      const { orgId } = decodedToken;
      const { code, number, agreement, ...params } = data;
      if (code && number) {
        const phone = parsePhoneNumber(`${code}${number}`, getShortCountryByCode(code));
        const phoneVer = { code, number: phone.nationalNumber, email: data.email };
        const response = await signupPhoneVerification(phoneVer);

        if (response.statusCode === 200) {
          const regResponse = await registration({
            ...params,
            code,
            accType: AccType.USER,
            marketingNotes: true,
            number: phone.nationalNumber,
          });
          if (regResponse.id) {
            const user = { ...regResponse, userId: regResponse.id };
            dispatch(updateUser({ isLogin: true, user }));
            navigate(`${RouterUrl.Organisation}/${orgId}`);
          } else {
            navigate(RouterUrl.Homepage);
          }
        }
        if (response.status === 'pending') {
          dispatch(updateSmsCodeTime(new Date().getTime()));
          navigate(RouterUrl.PhoneVerification, {
            state: { ...data, code, number: phone.nationalNumber, agreement: isNotifications, orgId },
          });
        } else {
          const errorCode = getInvalidType(response.statusCode, 'registration');
          setFieldError(Number, t(invalidHints[errorCode]));
        }
      }
    },
    [navigate, isNotifications],
  );

  const formikContextValue = {
    initialValues: {
      ...initialvalues,
      [Email]: state?.email,
      [Number]: state?.number,
      [Region]: state?.code,
    },
    validationSchema: registrationFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
    enableReinitialize: true,
  };

  return (
    <Flex flexDirection={['column', 'column', 'column']}>
      <H2>{t('registration.title')}</H2>
      <Div width="100%" mt="16px">
        <Formik {...formikContextValue}>
          {({ isValid, setFieldValue, isSubmitting }) => (
            <Form ref={formRef} autoComplete="on">
              <Flex gap="8px" flexDirection={['column', 'column', 'column', 'row']}>
                <FormikInput
                  autoFocus
                  id={Name}
                  name={Name}
                  placeholder={t('profile.name')}
                  autoComplete="given-name"
                  mb="8px"
                />
                <FormikInput
                  autoFocus
                  id={Surname}
                  name={Surname}
                  autoComplete="family-name"
                  placeholder={t('profile.surname')}
                  mb="16px"
                />
              </Flex>

              <FormikInput
                id={Email}
                name={Email}
                autoComplete="email"
                type="email"
                placeholder="E-mail"
                mb="16px"
                disabled
              />
              <FormikPhone codeId={Region} phoneId={Number} placeholder={t('registration.phone')} design="grey" />
              <FormikInput
                id={IdNumber}
                name={IdNumber}
                placeholder={t('profile.idNumber')}
                m="16px 0"
                onInput={(idNumber: number) => setFieldValue(IdNumber, idNumber)}
              />
              <FormikPasswordInput
                id={Password}
                name={Password}
                autoComplete="new-password"
                type="password"
                placeholder={t('registration.password')}
                caption={t('registration.passwordHint')}
              />
              <Flex alignItems="center" my="30px" gap="8px">
                <Checkbox shape="round" onClick={setNotifications} checked={isNotifications} />
                <BodySmall low>{t('registration.iAgree')}</BodySmall>
              </Flex>
              <Button
                disabled={!isValid || isSubmitting}
                design="primary"
                mt="24px"
                width="100%"
                size="large"
                type="submit"
              >
                {t('button.continue')}
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
    </Flex>
  );
};

export default Registration;
