import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import lowerCase from 'lodash/lowerCase';
import { Nullable } from 'tsdef';
import {
  BodySmall,
  Button,
  Caption,
  colors,
  Flex,
  H3,
  Link,
  MailFillIcon,
  PhoneIcon,
  PinFilledIcon,
  Icon,
} from '@beauty/beauty-market-ui';
import { getSelectedLanguage, SOCIETY_ICONS } from '../../../../constants';
import { handleSeeDirection } from '../../../../helpers/organisation';
import { getTranslation } from '../../../../helpers/utils';
import useSetCrumbsQuery from '../../../../routes/hooks/useSetCrumbsQuery';
import { RouterUrl } from '../../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setShowSearch } from '../../../../store/redux-slices/organisationSlice';
import { searchState } from '../../../../store/redux-slices/searchSlice';
import { Organisation, SocialNetworkType } from '../../types';
import { ContactBody, ContactInfo, ContactInfoBlock, ContactWrapper, IconWrapper, Map, TruncatedLink } from './style';

interface ContactProps {
  organisation: Organisation;
  showBtn: boolean;
  coordinates: Nullable<{ lat: number; lng: number }>;
  onMapClick: (value: string) => void;
}

const Contact = ({ organisation, showBtn, coordinates, onMapClick }: ContactProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { name, address, phone, email, social, addressDescription } = organisation;
  const { geolocation } = useAppSelector(searchState);
  const { orgId } = useParams();
  const crumbsQuery = useSetCrumbsQuery(name);

  return (
    <ContactWrapper>
      <H3>{t('organisation.contact.contact')}</H3>
      <ContactBody>
        <ContactInfoBlock>
          <Flex gap="8px" flexDirection="column">
            <BodySmall>{address}</BodySmall>
            {addressDescription && (
              <Caption color={colors.grey.dark}>{getTranslation(addressDescription, language)}</Caption>
            )}
            <Link size="s" onClick={() => handleSeeDirection(coordinates, geolocation)}>
              {t('organisation.contact.seeDirections')}
            </Link>
          </Flex>

          <ContactInfo mt={['32px', '32px', '32px', '40px']}>
            <PhoneIcon width="16" height="16" fill={colors.black.standard} />
            <Link design="black" size="s" href={`tel:${phone}`} dir="ltr">
              {phone}
            </Link>
          </ContactInfo>
          <ContactInfo mt="8px">
            <Icon flexShrink={0} width="16" height="16">
              <MailFillIcon />
            </Icon>
            <TruncatedLink design="black" size="s" href={`mailto:${email}`} title={email}>
              {email}
            </TruncatedLink>
          </ContactInfo>
          {social.map((item: SocialNetworkType) => (
            <ContactInfo marginTop="8px" key={item.id}>
              <IconWrapper>{SOCIETY_ICONS[item.label]}</IconWrapper>
              <Link design="black" size="s" href={item.link} target="_blank">
                {t(`organisation.contact.${lowerCase(item.label)}`)}
              </Link>
            </ContactInfo>
          ))}
        </ContactInfoBlock>
        <Map
          onClick={() => {
            onMapClick('map');
            dispatch(setShowSearch(false));
          }}
        >
          <PinFilledIcon width="25" height="25" fill={colors.blue.standard} />
        </Map>
      </ContactBody>
      {showBtn && (
        <Button
          size="large"
          width="100%"
          marginTop="40px"
          onClick={() => navigate(`${RouterUrl.Booking}/${orgId}${crumbsQuery}`, { state: { organisation } })}
        >
          {t('organisation.bookNow')}
        </Button>
      )}
    </ContactWrapper>
  );
};

export default Contact;
