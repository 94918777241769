import { serviceIcons } from '@beauty/beauty-market-ui';
import AnaAvatar from '../../assets/Ana.jpeg';
import GonAvatar from '../../assets/Gon.jpg';
import MiryamAvatar from '../../assets/Miryam.jpg';
import SashaAvatar from '../../assets/Sasha.jpeg';

export const data = t => ({
  reviews: [
    {
      id: '0',
      avatarUrl: SashaAvatar,
      username: 'Sasha Levi',
      date: '12.03.2024',
      rating: '5.0',
      text: t('home.reviews.review1'),
    },
    {
      id: '1',
      avatarUrl: MiryamAvatar,
      username: 'Miryam Sirotin',
      date: '15.03.2024',
      rating: '5.0',
      text: t('home.reviews.review2'),
    },
    {
      id: '2',
      avatarUrl: GonAvatar,
      username: 'Gon Shtein',
      date: '01.04.2024',
      rating: '5.0',
      text: t('home.reviews.review3'),
    },
    {
      id: '3',
      avatarUrl: AnaAvatar,
      username: 'Ana Magen',
      date: '02.04.2024',
      rating: '5.0',
      text: t('home.reviews.review4'),
    },
  ],
});

export const {
  AllServicesIcon,
  RelaxIcon,
  NailsIcon,
  MakeupIcon,
  EyebrowsIcon,
  DepilationIcon,
  BarberIcon,
  SkincareIcon,
  MassageIcon,
  HardwareIcon,
  HairIcon,
  ForMenIcon,
  MedicalAestheticIcon,
  ForEventIcon,
  FitnessIcon,
  NutritionIcon,
  GroomingIcon,
  NaturopathyIcon,
  MobileIcon,
  EducationIcon,
  StyleIcon,
} = serviceIcons;

export const ServiceIcons = {
  All: <AllServicesIcon />,
  Skincare: <SkincareIcon />,
  Massage: <MassageIcon />,
  Hardware: <HardwareIcon />,
  Hair: <HairIcon />,
  SPA: <RelaxIcon />,
  'Brow&Lash': <EyebrowsIcon />,
  'For Men': <ForMenIcon />,
  Cosmetology: <MedicalAestheticIcon />,
  Events: <ForEventIcon />,
  Fitness: <FitnessIcon />,
  Nails: <NailsIcon />,
  Makeup: <MakeupIcon />,
  Nutrition: <NutritionIcon />,
  Grooming: <GroomingIcon />,
  Naturopathy: <NaturopathyIcon />,
  Mobile: <MobileIcon />,
  Education: <EducationIcon />,
  Style: <StyleIcon />,
  Depilation: <DepilationIcon />,
  Barbershop: <BarberIcon />,
};

export const orderedTopCategories = [
  'Nails',
  'Hair',
  'Cosmetology',
  'Brow&Lash',
  'Massage',
  'Skincare',
  'Depilation',
  'SPA',
  'Barbershop',
  'For Men',
  'Makeup',
  'Fitness',
  'Hardware',
  'Nutrition',
  'Education',
  'Style',
  'Grooming',
  'Naturopathy',
  'Events',
  'Mobile',
];
