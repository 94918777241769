import { useEffect, useState } from 'react';
import { theme as defaultTheme, Theme } from '@beauty/beauty-market-ui';
import { MARKET_TEST_URL } from '../constants';
import { isRtl } from '../helpers/rtl';

export const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const rtl = isRtl();
  const host = window.location.origin;
  const isTestEnv = host.includes(MARKET_TEST_URL) || host.includes('localhost');

  useEffect(() => {
    document.dir = rtl ? 'rtl' : 'ltr';
    setTheme({ ...theme, rtl, testRtlFont: isTestEnv ? rtl : false });
  }, [rtl, isTestEnv]);

  return theme;
};
