import { ACCESS_TOKEN_KEY } from './constants';

class TokenService {
  getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

  removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY);

  setAccessToken = (value: string) => localStorage.setItem(ACCESS_TOKEN_KEY, value);
}

export const tokenService = new TokenService();
