import { AxiosRequestConfig } from 'axios';
import { tokenService } from '../../tokenService';

export class PatchHeaders {
  public resolve = (config: AxiosRequestConfig) => {
    if (config.headers) {
      const token = tokenService.getAccessToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  };
  /*
      Content-Type headers cant be overridden using axios defaults
      Issue: https://github.com/axios/axios/issues/2623
  */
  // if (config.method === 'patch') {
  //   // @ts-ignore
  //   config.headers['Content-Type'] = 'application/json';
  // }

  // return config;
  // };
}
