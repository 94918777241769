import { AxiosRequestConfig, AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { EndpointName } from '../api.types';
import { endpoints } from '../endpoints/endpoints';

export type NormalizedReturnTuple<T = any> = [AxiosError['response'] | undefined, undefined | AxiosResponse<T>];

type SimpleRequestParams = {
  endpoint: EndpointName;
  config?: AxiosRequestConfig;
  endpointParams?: any;
};

export const simpleRequestMethod =
  (instance: AxiosInstance, method: 'get' | 'delete') =>
  async <T = any>({ endpoint, endpointParams, config }: SimpleRequestParams): Promise<NormalizedReturnTuple<T>> => {
    const url = endpoints[endpoint](endpointParams);

    try {
      const resp = await instance[method](url, config);
      return [undefined, resp];
    } catch (e) {
      const error: any = e;
      return [error.response, undefined];
    }
  };

type ComplexRequestParams<T> = {
  endpoint: EndpointName;
  endpointParams?: any;
  data: T;
  language?: string;
  config?: AxiosRequestConfig;
};

export const complexRequestMethod =
  (instance: AxiosInstance, method: 'post' | 'put' | 'patch') =>
  async <T = any, Y = object>({
    endpoint,
    endpointParams,
    data,
    config,
  }: ComplexRequestParams<Y>): Promise<NormalizedReturnTuple<T>> => {
    const url = endpoints[endpoint](endpointParams);

    try {
      const resp = await instance[method](url, data, config);
      return [undefined, resp];
    } catch (e) {
      const error: any = e;
      return [config?.cancelToken ? error : error.response, undefined];
    }
  };
