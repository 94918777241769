import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, H3, Slider, SpecialistCard } from '@beauty/beauty-market-ui';
import { ShareOrganisationModal, SidebarSheet } from '../../../components';
import { isRtl } from '../../../helpers/rtl';
import { useToggle } from '../../../hooks/useToggle';
import { TeamMateType } from '../../../types';
import { SpecCard } from './SpecCard';
import { SpecialistInfo } from './SpecialistInfo/SpecialistInfo';

interface TeamProps {
  team: TeamMateType[];
  orgId: string;
  orgName: string;
}

const Team = ({ team, orgId, orgName }: TeamProps) => {
  const { t } = useTranslation();
  const rtl = isRtl();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFavouriteModalOpen, setIsFavouriteModalOpen] = useToggle();
  const [isShareModalOpen, setIsShareModalOpen] = useToggle();
  const [specialistIndex, setSpecialistIndex] = useState(-1);

  const handleClickOpen = index => {
    setSpecialistIndex(index);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const slides = team.map((teammate, i) => (
    <SpecialistCard
      key={teammate.id}
      name={teammate.name}
      description={teammate.specialization}
      avatar={<Avatar size="m" url={teammate.avatarUrl} rating={teammate.rating} />}
      onClick={() => handleClickOpen(i)}
    />
  ));

  return (
    <>
      <Slider
        title={<H3>{t('organisation.about.meetTheTeam')}</H3>}
        slides={slides}
        marginTop="160px"
        isPaginationEnabled
        rtl={rtl}
      />
      <SidebarSheet
        isOpen={isOpen}
        label={t('organisation.specialists.meetTheTeam')}
        descriptor={orgName}
        FooterBody={
          <Button size="medium" onClick={handleClose} width="100%">
            {t('button.close')}
          </Button>
        }
        onClose={handleClose}
      >
        <SpecCard
          name={team[specialistIndex]?.name}
          info={team[specialistIndex]?.specialization}
          imgUrl={team[specialistIndex]?.avatarUrl}
          onShare={setIsShareModalOpen}
          onLike={setIsFavouriteModalOpen}
        />
        <SpecialistInfo id={team[specialistIndex]?.id} headOrgSpecId={team[specialistIndex]?.headOrgSpecId} />
        {/* <FavouritePopup isOpen={isFavouriteModalOpen} handlePopupClose={setIsFavouriteModalOpen} /> */}
        {isShareModalOpen && (
          <ShareOrganisationModal onClose={setIsShareModalOpen} open={isShareModalOpen} organisationId={orgId} />
        )}
      </SidebarSheet>
    </>
  );
};

export default Team;
