import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { getShortCountryByCode } from '../../../../helpers';
import { UserType } from '../../../../types';

export enum PersonalDataFormFields {
  Name = 'name',
  Surname = 'surname',
  IdNumber = 'idNumber',
  Email = 'email',
  Region = 'code',
  Phone = 'number',
  Organization = 'orgId',
}

export interface PersonalDataFormTypes {
  [PersonalDataFormFields.Name]: string;
  [PersonalDataFormFields.Surname]: string;
  [PersonalDataFormFields.IdNumber]?: string;
  [PersonalDataFormFields.Email]: string;
  [PersonalDataFormFields.Region]: string;
  [PersonalDataFormFields.Phone]: string;
  [PersonalDataFormFields.Organization]?: string;
}

export const initialValues = (user: UserType | null, orgId?: string) => ({
  [PersonalDataFormFields.Name]: user?.name ?? '',
  [PersonalDataFormFields.Surname]: user?.surname ?? '',
  [PersonalDataFormFields.IdNumber]: user?.idNumber ?? '',
  [PersonalDataFormFields.Email]: user?.email ?? '',
  [PersonalDataFormFields.Region]: user?.code ?? '',
  [PersonalDataFormFields.Phone]: user?.number ?? '',
  [PersonalDataFormFields.Organization]: orgId || '',
});

export const personalDataFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [PersonalDataFormFields.Name]: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(50, t('validation.tooLong'))
      .trim()
      .required(t('validation.notEmpty')),
    [PersonalDataFormFields.Surname]: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(50, t('validation.tooLong'))
      .trim()
      .required(t('validation.notEmpty')),
    [PersonalDataFormFields.IdNumber]: Yup.string(),
    [PersonalDataFormFields.Email]: Yup.string().trim().required(t('validation.notEmpty')),
    [PersonalDataFormFields.Region]: Yup.string().trim(),
    [PersonalDataFormFields.Phone]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-code', t('validation.codeRequired'), function validateCode() {
        return !!this.parent[PersonalDataFormFields.Region];
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[PersonalDataFormFields.Region];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
  });
