import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CommonActions } from '../actions';
import { RootState } from '../store';

export interface HeaderState {
  isOnTop: boolean;
  isShowMenu: boolean;
}

const initialState: HeaderState = {
  isOnTop: false,
  isShowMenu: true,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    updateIsOnTop: (state, action: PayloadAction<{ isOnTop: boolean }>) => {
      state.isOnTop = action.payload.isOnTop;
    },
    updateShowMenu: (state, action: PayloadAction<{ isShowMenu: boolean }>) => {
      state.isShowMenu = action.payload.isShowMenu;
    },
  },
  extraReducers: builder => builder.addCase(CommonActions.logout, () => initialState),
});

export const { updateIsOnTop, updateShowMenu } = headerSlice.actions;

export const headerState = (state: RootState) => state.header;

export default headerSlice.reducer;
