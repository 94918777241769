import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { has } from 'lodash';
import { Button } from '@beauty/beauty-market-ui';
import { AccType, InvalidVariants } from '../../../constants';
import { getInvalidType, loginByPhone, loginPhoneVerification, redirectAfterLogin, smsForm } from '../../../helpers';
import { useGetSecondsLeft } from '../../../hooks/useGetSecondsLeft';
import { useAppDispatch } from '../../../store/hooks';
import { setIsCurrentLocation } from '../../../store/redux-slices/searchSlice';
import { updateSmsCodeTime, updateUser } from '../../../store/redux-slices/userSlice';
import { initialSmsCodeValues, LoginSmsCodeForm, loginSMSCodeFormValidationSchema } from '../Login.definitions';

const SMSCodeForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const { code, number } = state;
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isDefaultValue, setDefaultValue] = useState(true);

  const secondsLeft = useGetSecondsLeft();

  const onFormSubmit = useCallback(
    async (data: LoginSmsCodeForm) => {
      setLoading(true);
      const values = { code, number, otp: data.smscode, accType: AccType.USER };
      const response = await loginByPhone(values);

      if (response.success) {
        setInvalidType(null);
        const user = { ...response.data, userId: response.data.id };
        dispatch(updateUser({ isLogin: true, user }));

        !has(state, 'redirectedFrom') && dispatch(setIsCurrentLocation(true));
        redirectAfterLogin(navigate, state);
      } else setInvalidType(getInvalidType(response.statusCode || response.status, 'codeVerification'));
      setLoading(false);
    },
    [code, number, invalidType],
  );

  const handleResendClick = async () => {
    setLoading(true);
    setInvalidType(null);
    const phoneVer = { code, number };
    const response = await loginPhoneVerification(phoneVer);
    if (response.status === 'pending') {
      dispatch(updateSmsCodeTime(new Date().getTime()));
      setLoading(false);
    }
  };

  const formikContextValue = {
    initialValues: initialSmsCodeValues,
    validationSchema: loginSMSCodeFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  return (
    <>
      {smsForm(formikContextValue, invalidType, setInvalidType, isDefaultValue, t)}
      <Button
        disabled={secondsLeft || isLoading}
        design="quaternary"
        mt="16px"
        width="100%"
        size="large"
        onClick={() => {
          handleResendClick();
          setDefaultValue(true);
        }}
      >
        {`${t(`registration.resendCode`)}${secondsLeft ? `${` 00:`}${`0${secondsLeft}`.slice(-2)}` : ``}`}
      </Button>
    </>
  );
};

export default SMSCodeForm;
