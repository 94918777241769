import qs from 'query-string';
import { EndpointName } from './api.types';
import { SELECTED_LOCALE_KEY, COOKIES_ACCEPTED_KEY } from './constants';
import { endpointsWhitelist } from './endpoints/endpoints';

export const getSelectedLocale = () => localStorage.getItem(SELECTED_LOCALE_KEY) || 'en-US';
export const setSelectedLocale = (locale: string) => localStorage.setItem(SELECTED_LOCALE_KEY, locale);

export const getCookiesAccepted = () => localStorage.getItem(COOKIES_ACCEPTED_KEY)?.toLowerCase() === 'true';
export const setCookiesAccepted = (isAccepted: string) => localStorage.setItem(COOKIES_ACCEPTED_KEY, isAccepted);

export const isWhitelistAPI = (endpoint: EndpointName) =>
  endpointsWhitelist.some((permittedEndpoints: any) => Object.values(permittedEndpoints).includes(endpoint));

export const expandEndpointByParams = <T>(params: T) => ({
  params,
  paramsSerializer: (serializedParams: any) => qs.stringify(serializedParams, { arrayFormat: 'comma' }),
});
