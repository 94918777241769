import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { tokenService } from '../../api';
import { RouterUrl } from '../../routes/routes';
import { CommonActions, useAppDispatch } from '../../store';

export const AuthGuard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = tokenService.getAccessToken();

  useEffect(() => {
    if (!token) {
      dispatch(CommonActions.logout());
      navigate(RouterUrl.Login);
    }
  }, [dispatch, navigate, token]);

  return <Outlet />;
};
